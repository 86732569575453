<template>
  <div
    class="mainContent"
    v-loading="templatePdf == ''"
    :style="{ height: heightDialog }"
  >
    <embed
      v-show="templatePdf != ''"
      :src="'data:application/pdf;base64,' + templatePdf"
      width="100%"
      palette="red|black"
      :height="heightDialog"
    />
    <footer class="foot" v-show="templatePdf != ''">
      <p>
        © 2008-2021 CloudCC Inc.{{
          $t(
            "label.allrightsreserved.allrightsreserved.customerServiceHotline"
          )
        }}：400-642-2008
      </p>
    </footer>
  </div>
</template>

<script>
import * as invoicePayApi from "../api.js";

export default {
  data() {
    return {
      heightDialog: `${document.body.offsetHeight - 110}px`,
      templatePdf: "",
    };
  },
  created() {
    this.invoicePreview();
  },
  mounted() {
    document.getElementById("lightning-loading").style.display = "none";
  },
  //方法集合
  methods: {
    //获取发票预览
    async invoicePreview() {
      let result = await invoicePayApi.getTemplatePdfNoToken({
        id: this.$route.params.templateId,
        objid: this.$route.params.objId,
        recordId: this.$route.params.id,
        type: "Invoices",
        userid: this.$route.params.userId,
        username: this.$route.params.loginName,
        orgid: this.$route.params.orgId,
      });
      if (result.data.result) {
        this.templatePdf = result.data.data.templatePdf;
        
      }
    },
    
    printBtn() {
      window.print();
    },
  },
};
</script>
<style lang='scss' scoped>
.tag {
  margin-bottom: 5px;
}
footer {
  margin-top: 20px;
  p {
    width: 100%;
    text-align: center;
    color: #080707;
  }
}
.big_title svg {
  width: 20px;
  cursor: pointer;
  height: 20px;
  margin-top: 16px;
  float: right;
}
.big_title {
  border-bottom: 1px solid #dddbda;
  height: 48px;
  span {
    display: inline-block;
    line-height: 48px;
  }
}
.table {
  border: 1px solid #dddbda;
  margin-bottom: 20px;
  p {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #080707;
    line-height: 35px;
    margin-left: 15px;
    font-weight: bold;
  }
  table {
    border-collapse: collapse;
    border-color: #ffffff;
    page-break-after: alway;
    tr {
      .tit {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
      }
      th {
        height: 40px;
        background: #f3f2f2;
        padding: 0 14px;
        box-sizing: border-box;
      }
      td {
        height: 40px;
        padding: 0 14px;
        box-sizing: border-box;
      }
      td:last-child {
        padding-right: 10px;
      }
    }
  }
}

table tr td:last-child {
  padding-right: 10px !important;
}
.mark_box {
  display: flex;
  flex-wrap: wrap;
  .mark {
    width: 50%;
    line-height: 40px;
    .mark_title {
      width: 30%;
      text-align: right;
      display: inline-block;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #080707;
      overflow: hidden;
    }
    .mark_text {
      width: 60%;
      float: right;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #666666;
      word-break: break-all;
    }
  }
}

.rotate90 {
  transform: rotate(-90deg);
}
.mainContent {
  padding: 16px 40px 10px;
}

.groupTitle {
  font-weight: bold;
  font-family: SourceHanSansCN-Medium;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  padding-left: 10px;
  border-radius: 3px;
  margin-top: 8px;
  background: #f3f2f2;
  margin-bottom: 0;
  color: #080707;
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    display: flex;
    ::v-deep font {
      display: flex;
      marquee {
        height: 100%;
      }
    }
  }

  &:hover {
    background: #eef1f6;
  }

  > i {
    font-size: 14px;
    color: #000;
    margin-right: 8px;
  }
}
</style>